<template>
    <div class="body">
       <div class="subscribe_data">
           <div class="subscribe_name">
               <div class="subscribe_name1">
                   <div class="subscribe_icon_img">
                       <img style="width: 5.6vw" src="https://seetop-1257860468.file.myqcloud.com/html/img/icon2023011804.png">
                   </div>
                   <input placeholder="请填写姓名" maxlength="11" type="text" v-model="reservename" />
               </div>
               <div class="subscribe_radio">
                   <div :class="[reservesex == '1'? 'subscribe_radio_yes' : 'subscribe_radio_no']"  @click="switchSex('1')"></div>
                   <div @click="switchSex('1')">先生</div>
                   <div :class="[reservesex == '2'? 'subscribe_radio_yes' : 'subscribe_radio_no']"  @click="switchSex('2')"></div>
                   <div @click="switchSex('2')">女士</div>
               </div>
           </div>
           <div class="subscribe_phone">
               <div class="subscribe_phone1">
                   <div class="subscribe_icon_img">
                       <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon2023011805.png">
                   </div>
                   <input placeholder="请填写手机" maxlength="11" type="number" v-model="reservephone"/>
               </div>
           </div>
       </div>
        <div class="subscribe_choose" >
            <div class="subscribe_choose_title">人数</div>
            <div class="subscribe_choose_box">
                <div v-for="(item,index) in number" :key="index" :class="reservenumber==item? 'subscribe_choose_box_yes' : 'subscribe_choose_box_no'" @click="switchNumber(item)">{{item}}</div>
            </div>
        </div>
        <div class="subscribe_choose" >
            <div class="subscribe_choose_title">日期</div>
            <div class="subscribe_choose_box">
                <div v-for="(item,index) in date" :key="index"  :class="reservedate==item? 'subscribe_choose_box_yes' : 'subscribe_choose_box_no'"  @click="switchDate(item)">
                    <div>{{index}}</div>
                    <div>{{item}}</div>
                </div>
            </div>
        </div>
        <div class="subscribe_choose" >
            <div class="subscribe_choose_title">时间</div>
            <div class="select-time-container">
                <div v-for="(item,index) in time" :key="index"  :class="reservetime==item? 'subscribe_choose_box_yes' : 'subscribe_choose_box_no'"  @click="switchTime(item)">{{item}}</div>
            </div>
        </div>
        <div class="subscribe_table">
            <div>是否愿意包厢忙碌时段调整座位</div>
            <div class="subscribe_radio">
                <div :class="[reserveCanChange == '1'? 'subscribe_radio_yes' : 'subscribe_radio_no']" @click="switchCanChange(1)"></div>
                <div>是</div>
                <div :class="[reserveCanChange == '0'? 'subscribe_radio_yes' : 'subscribe_radio_no']" @click="switchCanChange(0)"></div>
                <div>否</div>
            </div>
        </div>
        <div class="subscribe_choose" >
            <div class="subscribe_choose_title">大厅/包厢</div>
            <div class="subscribe_choose_box">
                <div v-for="(item,index) in room" :key="index" :class="reserveRoom==item? 'subscribe_choose_box_yes' : 'subscribe_choose_box_no'" @click="switchRoom(item)">{{item}}</div>
            </div>
        </div>
        <div class="subscribe_product" v-if="order.length > 0">
            <div class="subscribe_product_title">
                <div></div>
                <div>套餐推荐·随时可退</div>
                <div></div>
            </div>
            <div class="subscribe_product_box" v-for="(item,index) in order" :key="index" @click="switchOrder(item.id)">
                <div :class="reserveorder==item.id ? 'subscribe_product_choose_yes' : 'subscribe_product_choose'"></div>
                <img class="subscribe_product_img" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item.trademark"/>
                <div class="subscribe_product_content">
                    <div class="subscribe_product_content_title">{{item.gooods_name}}</div>
                    <!--<div class="subscribe_product_content_tip">半年销量800</div>-->
                    <div class="subscribe_product_content_money">
                        <div>￥</div>
                        <div>{{item.total_fee/100}}</div>
                        <!--<div>4.9折</div>-->
                        <!--<del>￥1599</del>-->
                    </div>
                    <div class="subscribe_product_content_time">{{item.create_time}}</div>
                </div>
            </div>
        </div>
        <img @click="reserveSave()" class="details_button" src="https://seetop-1257860468.file.myqcloud.com/html/img/button2023011801.png">
    </div>
</template>

<script>
    import { globalConfig } from '../../utils/mixin'
    import { hrefUrlCommon } from '../../utils/tools'

    export default {
        name: 'Comment',
        mixins: [globalConfig],
        data () {
            return {
                order: [],
                date: [],
                number: [],
                room: ['均可', '大厅', '包厢'],
                time: [],
                reserve: [],
                reservetime: '',
                reservedate: '',
                reservenumber: '',
                reservesex: '1',
                reservename: '',
                reservephone: '',
                reserveorder: '',
                reserveCanChange: '1',
                reserveRoom: '均可'
            }
        },
        methods: {
            hrefUrlCommon,
            init: function () {
                this.axios.post(this.apiUrl + 'online/reserve/index', {
                    id: this.$route.params.id
                }).then((response) => {
                    this.order = response.data.data.order
                    this.date = response.data.data.date
                    this.number = response.data.data.number
                    this.time = response.data.data.time
                    this.room = response.data.data.room
                    this.reservetime = response.data.data.time[0]
                    this.reservenumber = response.data.data.number[0]
                    this.reservedate = response.data.data.date['今天']
                    // this.reserveorder = response.data.data.order[0].id
                    this.reserveRoom = response.data.data.room[0]
                    console.log(this.reserve)
                })
            },
            switchNumber: function (i) {
                this.reservenumber = i
            },
            switchTime: function (i) {
                this.reservetime = i
            },
            switchDate: function (i) {
                this.reservedate = i
            },
            switchSex: function (i) {
                this.reservesex = i
            },
            switchCanChange: function (i) {
              this.reserveCanChange = i
            },
              switchRoom: function (i) {
                this.reserveRoom = i
              },
            switchOrder: function (i) {
                if (this.reserveorder == i) {
                  this.reserveorder = ''
                } else {
                  this.reserveorder = i
                }
            },
            reserveSave: function () {
              this.reserve.id = this.$route.params.id
              this.reserve.time = this.reservetime
              this.reserve.data = this.reservedate
              this.reserve.number = this.reservenumber
              this.reserve.sex = this.reservesex
              this.reserve.orderId = this.reserveorder
              this.reserve.name = this.reservename
              this.reserve.phone = this.reservephone
              this.reserve.reserveCanChange = this.reserveCanChange
              this.reserve.reserveRoom = this.reserveRoom
              console.log(this.reserve)
                //  校验
                if (this.reservename && this.reservephone && this.reservedate && this.reservetime && this.reservenumber) {} else {
                  this.$toast('请填写完整信息')
                  return
                }
                if (this.reservephone.length !== 11) {
                  this.$toast('请填写正确的手机号')
                  return
                }
                const that = this
                this.axios.post(this.apiUrl + 'online/reserve/reserve', {
                    id: this.$route.params.id,
                    time: this.reservetime,
                    date: this.reservedate,
                    number: this.reservenumber,
                    sex: this.reservesex,
                    phone: this.reservephone,
                    name: this.reservename,
                    orderId: this.reserveorder,
                    canChange: this.reserveCanChange,
                    room: this.reserveRoom
                }).then((response) => {
                    if (response.data.status) {
                        this.$toast({
                            message: '预约成功',
                            onClose () {
                                that.$router.push('/subscribe/list')
                            }
                        })
                    } else {
                        this.$toast(response.data.msg)
                    }
                })
            }
        },
        created () {
            this.init()
            this.reserveorder = this.$route.params.orderid || ''
        }
    }
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }
    .subscribe_data{
        background: #fff;
        margin-top: 3vw;
    }
    .subscribe_name{
        display: flex;
        height: 15vw;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #cecece;
        padding-right: 8vw;
    }
    .subscribe_phone{
        display: flex;
        height: 15vw;
        align-items: center;
    }
    .subscribe_name1{
        display: flex;
        align-items: center;
    }
    .subscribe_phone1{
        display: flex;
        align-items: center;
    }
    .subscribe_icon_img{
        width: 14vw;
        height: 6vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .subscribe_icon_img img{
        width: 5vw;
    }
    input{
        width: 30vw;
        height: 5vw;
        border: none;
        font-size: 3.6vw;
    }
    input::placeholder{
        color: #cecece;
    }
    .subscribe_radio{
        display: flex;
        align-items: center;
        font-size: 3.6vw;
        color: #333;
    }
    .subscribe_radio_no{
        width: 4vw;
        height: 4vw;
        border-radius: 4vw;
        border: 1px solid #ccc;
        margin: 0 2vw 0 4vw;
    }
    .subscribe_radio_yes{
        width: 4vw;
        height: 4vw;
        border-radius: 4vw;
        border: 1px solid #DC9A64;
        margin: 0 2vw 0 4vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/icon2023011806.png") center center no-repeat #DC9A64;;
        background-size: 1.5vw;
    }
    .subscribe_table{
        width: 100vw;
        height: 12vw;
        color: #333333;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 3vw;
        padding: 0 8vw 0 4vw;
    }
    .subscribe_choose{
        width: 100vw;
        padding: 3vw 0 4vw 4vw ;
        background: #fff;
        margin-top: 3vw;
    }
    .subscribe_choose_title{
        font-size: 3.8vw;
        font-weight: 600;
        color: #333;
    }
    .subscribe_choose_box{
        display: flex;
        flex-wrap: wrap;
    }
    .select-time-container div {
        min-width: 5rem;
    }
    .select-time-container {
        display: flex;
        flex-flow: row;
        overflow: scroll;
        width: 100%;
    }
    .subscribe_choose_box_no{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 20.5vw;
        height: 12vw;
        border: 1px #cecece solid;
        border-radius: 1vw;
        margin: 2vw 3vw 0 0;
        text-align: center;
        line-height: 12vw;
        font-size: 3.2vw;
        color: #666;
    }
    .subscribe_choose_box_yes{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 20.5vw;
        height: 12vw;
        border: 1px solid #FDA257;
        border-radius: 1vw;
        margin: 2vw 3vw 0 0;
        text-align: center;
        line-height: 12vw;
        font-size: 3.2vw;
        color: #FDA257;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/bg2023011801.png") center center no-repeat #FDE4CF;;
        background-size: 100% 100%;
    }
    .subscribe_choose_box_no div,.subscribe_choose_box_yes div{
        width: 20.5vw;
        height: 4vw;
        line-height: 4vw;
    }
    .subscribe_choose_box_no div:nth-of-type(1),.subscribe_choose_box_yes  div:nth-of-type(1){
        margin-top: 1vw;
    }
    .subscribe_choose_box_no div:nth-of-type(2),.subscribe_choose_box_yes  div:nth-of-type(2){
        margin-top: -2vw;
    }
    .details_button{
        width: 86vw;
        margin: 12vw 7vw 5vw;
        text-align: center;
    }
    .subscribe_product{
        width: 100vw;
        margin-top: 3vw;
        padding: 5vw 4vw;
        background: #fff;
    }
    .subscribe_product_title{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #CDCDCD;
        font-size: 3.8vw;
    }
    .subscribe_product_title div:nth-of-type(1),.subscribe_product_title div:nth-of-type(3){
        width: 28vw;
        border-bottom: 1px solid #CDCDCD;
        margin: 0 1vw;
    }
    .subscribe_product_box{
        display: flex;
        align-items: center;
        margin-top: 4vw;
    }
    .subscribe_product_choose{
         width: 4vw;
         height: 4vw;
         border-radius: 4vw;
         border: 1px solid #ccc;
         margin: 0 4vw 0 2vw;
     }
    .subscribe_product_choose_yes{
        width: 4vw;
        height: 4vw;
        border-radius: 4vw;
        margin: 0 4vw 0 2vw;
        border: 1px solid #DC9A64;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/icon2023011806.png") center center no-repeat #DC9A64;;
        background-size: 1.5vw;
    }
    .subscribe_product_img{
        width: 20vw;
        height: 20vw;
        border-radius: 2vw;
        border:1px solid #ececec;
        margin:0 4vw 0 0;
    }
    .subscribe_product_content_title{
        font-size: 4vw;
        font-weight: 600;
    }
    .subscribe_product_content_tip{
        font-size: 3.2vw;
        color: #B4B4B4;
        margin: 1.2vw 0;
    }
    .subscribe_product_content_time{
        font-size: 3.8vw;
        color: #B4B4B4;
        margin-top: 1.5vw;
    }
    .subscribe_product_content_money{
        display: flex;
        align-items: flex-end;
        height: 6vw;
        margin-top: 1.5vw;
    }
    .subscribe_product_content_money div:nth-of-type(1){
        font-size: 2.8vw;
        color: #F88A66;
        margin-top: 0.8vw;
        font-weight: 800;
    }
    .subscribe_product_content_money div:nth-of-type(2){
        font-size: 3.8vw;
        font-weight: 800;
        color: #F88A66;
    }
    .subscribe_product_content_money div:nth-of-type(3){
        font-size: 2.8vw;
        padding: 0.5vw 0.8vw;
        color: #FED8B8;
        border: 1px solid #FED8B8;
        margin: 0 1.5vw;
    }
    .subscribe_product_content_money del{
        font-size: 3.6vw;
        color: #CDCDCD;
    }
</style>
